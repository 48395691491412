import React from 'react';
import logo from '../../assets/bizdeploy-logo.svg';
import '../header/Header.css';


const Header = () => {
  return (
    <header className="App-header">
      <img src={logo} alt="BizDeploy Logo" className="logo" />
      <p className="tagline">Deploying Your Business' Digital Needs</p>
      <a 
                href="https://ramadan.mya.org.au/t/bizdeploy" 
                target="_blank"
                rel="noopener noreferrer"
                className="partnership-link"
              >
                 Ramadan Special 2025
              </a>    </header>
  );
};

export default Header;