import React from 'react';
import jsIcon from '../../assets/javascript-icon.svg';
import reactIcon from '../../assets/react-icon.svg';
import netlifyIcon from '../../assets/netlify-icon.svg';
import digitalOceanIcon from '../../assets/digitalocean-icon.svg';
import strapiIcon from '../../assets/strapi-icon.svg';
import stripeIcon from '../../assets/stripe-logo.svg';

const Technologies = () => {
  return (
    <div id="technologies">
      <h2 className="feature-heading">Technologies</h2>
      <p>
        A few of the modern, industry-leading technologies we use to build custom websites.
      </p>
      
      <div className="tech-grid">
        <div className="tech-card">
          <div className="tech-header">
            <img src={jsIcon} alt="JavaScript" className="tech-icon" />
            <h3>JavaScript</h3>
          </div>
          <p>
            We leverage JavaScript's versatility to create dynamic, interactive websites that engage users
            and provide smooth, responsive experiences across all devices and browsers.
          </p>
        </div>
        
        <div className="tech-card">
          <div className="tech-header">
            <img src={reactIcon} alt="React" className="tech-icon" />
            <h3>React</h3>
          </div>
          <p>
            Our team builds modern user interfaces with React, creating component-based 
            architecture that ensures your website is maintainable, efficient, and highly interactive.
          </p>
        </div>
        
        <div className="tech-card">
          <div className="tech-header">
            <img src={netlifyIcon} alt="Netlify" className="tech-icon" />
            <h3>Netlify</h3>
          </div>
          <p>
            We deploy your websites on Netlify for unparalleled performance, security, and reliability. 
            Enjoy continuous deployment, global CDN, and industry-leading uptime for your business.
          </p>
        </div>
        
        <div className="tech-card">
          <div className="tech-header">
            <img src={digitalOceanIcon} alt="DigitalOcean" className="tech-icon" />
            <h3>DigitalOcean</h3>
          </div>
          <p>
            For custom applications requiring robust server infrastructure, we utilize DigitalOcean
            to provide scalable, secure hosting solutions with predictable pricing and excellent performance.
          </p>
        </div>
        
        <div className="tech-card">
          <div className="tech-header">
            <img src={strapiIcon} alt="Strapi" className="tech-icon" />
            <h3>Strapi</h3>
          </div>
          <p>
            Strapi is a headless CMS used to create websites, mobile applications, eCommerce sites and APIs. 
            It boasts flexibility and control over the content structure of your app.
          </p>
        </div>
        
        <div className="tech-card">
          <div className="tech-header">
            <img src={stripeIcon} alt="Stripe" className="tech-icon" />
            <h3>Stripe</h3>
          </div>
          <p>
            We integrate Stripe's secure payment processing to enable seamless e-commerce 
            capabilities for your business, with intuitive checkout flows and robust fraud protection.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Technologies;