import React from 'react';
import forgottenNoorLogo from '../../assets/forgotten-noor-client.png';
import harrisCEO from '../../assets/harris-forgotten-noor.jpeg';

const Testimonials = () => {
  return (
    <div id="testimonials">
      <h2 className="feature-heading">What Our Clients Say</h2>
      
      <div className="testimonial-section">
        <div className="testimonial-card">
          <div className="testimonial-content">
            <div className="quote-mark">"</div>
            <p className="testimonial-text">
              BizDeploy transformed our vision lightning fast into a stunning website. 
            </p>
            <div className="client-info">
              <div className="client-profile">
                <div className="client-details">
                  <p>Forgotten Noor</p>
                  <a href="https://forgottennoor.com.au" target="_blank" rel="noopener noreferrer" className="client-link">forgottennoor.com.au</a>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-image forgotten-noor-brand">
            <img src={forgottenNoorLogo} alt="Forgotten Noor" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;