import React, { useState } from "react";

const ContactNegative = () => {
  const appsScriptUrl =
    "https://script.google.com/macros/s/AKfycby3RVWkKJJ_0931thQM6cD_V8F1eYzBpnJfL81581LNIr_UbEWD14i5H0_h8YAMUHvozw/exec";
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  // UI improvements to communicate to user if email has been sent successfully
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(appsScriptUrl, {
        mode: "no-cors", // Not sure if this is a security risk
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, name, message }),
      });
      if (response.status === 0) {
        // This API redirects with response code 302 but appears to return 0
        setEmail("");
        setName("");
        setMessage("");
        setIsSuccess(true);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div id="contact" className="contact-section">
      <div className="contact-container">
        <div className="contact-info">
          <h3>Let's Discuss Your Next Project</h3>
          <p>
            Wanting to kickstart your next project? We're here to help you create
            a website that truly reflects your brand and meets your business
            goals.
          </p>
          <div className="contact-methods">
            <div className="contact-method">
            </div>
            <div className="contact-method">
              <div className="contact-icon">📱</div>
              <div className="contact-text">
                <h4>Call Us</h4>
                <a href="tel:+6149841001">+61 49 841 001</a>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder={
                  isSuccess
                    ? "Email sent successfully"
                    : "Tell us about your project"
                }
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="cta-button primary">
              {isSubmitting ? "Submitting..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactNegative;
