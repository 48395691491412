import React, { useState, useContext } from 'react';
import favicon from '../../assets/favicon.svg';
import { ThemeContext } from '../../context/ThemeContext';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { darkMode, toggleTheme } = useContext(ThemeContext);
  
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <div className="logo-container">
            <img src={favicon} alt="BizDeploy" className="nav-logo" />
          </div>
        </a>
        
        <div className="mobile-menu-toggle" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          <div className={`hamburger ${mobileMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        
        <ul className={`nav-menu ${mobileMenuOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <a href="#services" className="nav-link" onClick={() => setMobileMenuOpen(false)}>Services</a>
          </li>
          <li className="nav-item">
            <a href="#technologies" className="nav-link" onClick={() => setMobileMenuOpen(false)}>Technologies</a>
          </li>
          <li className="nav-item">
            <a href="#wix-migration" className="nav-link highlight-link" onClick={() => setMobileMenuOpen(false)}>Screener</a>
          </li>
          <li className="nav-item">
            <a href="#testimonials" className="nav-link" onClick={() => setMobileMenuOpen(false)}>Testimonials</a>
          </li>
          <li className="nav-item">
            <a href="#contact" className="nav-link contact-link" onClick={() => setMobileMenuOpen(false)}>Contact Us</a>
          </li>
          <li className="nav-item theme-toggle-item">
            <button onClick={toggleTheme} className="theme-toggle" aria-label="Toggle dark mode">
              {darkMode ? (
                <span className="mode-icon">☀️</span>
              ) : (
                <span className="mode-icon">🌙</span>
              )}
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;