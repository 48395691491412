import React, { useState } from 'react';
import axios from 'axios';
import ContactPositive from '../contact/ContactPositive';
import ContactNegative from '../contact/ContactNegative';
import '../wixMigration//WixMigration.css';

const WixMigration = () => {
  const [url, setUrl] = useState('');
  const [result, setResult] = useState<null | { isWix: boolean; message: string }>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorDetails, setErrorDetails] = useState('');

  const checkWebsite = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Reset states
    setLoading(true);
    setResult(null);
    setError('');
    setErrorDetails('');
    
    // Validate URL
    let processedUrl = url.trim();
    if (!processedUrl.startsWith('http://') && !processedUrl.startsWith('https://')) {
      processedUrl = 'https://' + processedUrl;
    }
    
    // Basic URL validation using regex
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?([\/\w\.-]*)*\/?$/;
    
    if (!urlPattern.test(processedUrl)) {
        setError('Invalid URL format. Please enter a valid website address.');
        setLoading(false);
        return;
    }
    
    try {
      // Call your API
      const response = await axios.get(`https://data.halalscout.com.au/api/site/search?url=${encodeURIComponent(processedUrl)}`);
      
      // Process response
      if (response.data.isIsraeliService) {
        setResult({
          isWix: true,
          message: "This site appears to be built with unethical based technology such as Israeli based website builder Wix."
        });
      } else {
        setResult({
          isWix: false,
          message: "From our analysis this website doesn't appear to be built with unethical based technology such as Israeli based Wix."
        });
      }
    } catch (err: any) {
      console.error('Check error:', err);
      
      // Handle the new backend error format
      if (err.response?.data?.error) {
        setError(err.response.data.error);
        if (err.response.data.details) {
          setErrorDetails(err.response.data.details);
        }
      } else {
        setError('Failed to analyze the website. Please ensure the URL is correct and try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="wix-migration" className="wix-migration-section">
      <h2 className="feature-heading">Unethical Technologies Screener</h2>
      <p className="feature-text">
         Avoid your business being linked to any unethically sourced technologies. Switch to an ethical provider now. 
      </p>
      
      <div className="migration-container">
        <div className="site-checker-card">
          <h3>Check Your Website</h3>
          <p>Check if a website is built with any unethical sourced technology. Enter the URL below to analyze.</p>
          
          // ...existing code...
<form onSubmit={checkWebsite} className="site-checker-form">
  <div className="form-input-group">
    <input 
      type="text" 
      value={url} 
      onChange={(e) => setUrl(e.target.value)}
      placeholder="Enter website URL (e.g., example.com)" 
      className="site-checker-input"
      required
    />
    <button 
      type="submit" 
      className="site-checker-button"
      disabled={loading}
    >
      {loading ? 'Checking...' : 'Check Website'}
    </button>
  </div>
  
  {error && (
    <div className="site-checker-error">
      <p className="error-main">{error}</p>
      {errorDetails && <p className="error-details">{errorDetails}</p>}
    </div>
  )}
</form>

{result && (
  <div className={`site-checker-result ${result.isWix ? 'is-wix' : 'not-wix'}`}>
    <div className="result-icon">
      {result.isWix ? '⚠️' : '✅'}
    </div>
    <div className="result-content">
      <h4>{result.message}</h4>
      {result.isWix && (
          <ContactPositive />
        
      )}
      {!result.isWix && (
        <p>However, if you're looking to upgrade or kickstart a new website, we're here to help! This Ramadan, <b className="partnership-link">10% of your purchase</b> goes to our
        _<a 
                href="https://ramadan.mya.org.au/t/bizdeploy"
                rel="noopener noreferrer"
                className="partnership-link"
              >
                 Penny Appeal Ramadan Fundraiser
              </a>
          <ContactNegative />
        </p>
        
      )}
    </div>
  </div>
)}

<div className="share-buttons">
  <h4>Share this website:</h4>
  <a
    href="https://api.whatsapp.com/send?text=Check%20if%20your%20website%20is%20ethically%20sourced through%20https://bizdeploy.com.au"
    target="_blank"
    rel="noopener noreferrer"
    className="share-button whatsapp"
  >
    Share on WhatsApp
  </a>
  <a
    href="https://www.facebook.com/sharer/sharer.php?u=https://bizdeploy.com.au"
    target="_blank"
    rel="noopener noreferrer"
    className="share-button facebook"
  >
    Share on Facebook
  </a>
</div>
        </div>
      </div>
      <div className="migration-cta">
        <h3>Ready to Upgrade Your Website?</h3>
        <p>Get in touch today for a free consultation about migrating from Wix to a custom-built website.</p>
        <a href="#contact" className="cta-button primary">Contact Us</a>
      </div>
    </div>
  );
};

export default WixMigration;