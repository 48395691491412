import React from 'react';
import './App.css';

// Import components
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import Header from './components/header/Header';
import ScalableSolutions from './components/scalableSolutions/ScalableSolutions';
import Technologies from './components/technologies/Technologies';
import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Hero from './components/hero/hero';
import WixMigration from './components/wixMigration/WixMigration';  // Import the new component

// Import ThemeProvider
import { ThemeProvider } from './context/ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <Navbar />
        <Header />
        <section className="main-section">
          <div className="container">
            <WixMigration />  {/* Add the new component here */}
            <Technologies />
            <Testimonials />
            <Contact />
          </div>
        </section>
        
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;