import React from 'react';
import logo from '../../assets/bizdeploy-logo.svg';

const Footer = () => {
  return (
    <footer className="App-footer">
      <div className="footer-content">
        <div className="footer-brand">
          <img src={logo} alt="BizDeploy" className="footer-logo" />
          <p>Custom Websites That Reflect Your Brand</p>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <h4>Navigation</h4>
            <ul>
              <li><a href="#services">Services</a></li>
              {/* <li><a href="#work">Our Work</a></li> */}
              <li><a href="#technologies">Technologies</a></li>
              <li><a href="#testimonials">Testimonials</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Contact</h4>
            <ul>
              <li><a href="mailto:hello@bizdeploy.com">hello@bizdeploy.com</a></li>
              <li><a href="tel:+61400000000">+61 400 000 000</a></li>
              <li>Sydney, Australia</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>© {new Date().getFullYear()} BizDeploy. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;